import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {SeazoneIconsComponent} from './components/seazone-icons/seazone-icons.component';
import {SeazoneIconRegistryService} from './services/seazone-icon-registry.service';

export const seazoneIconRegistryServiceFactory = (): SeazoneIconRegistryService => new SeazoneIconRegistryService();

@NgModule({
  declarations: [SeazoneIconsComponent],
  imports: [
    CommonModule,
  ],
  exports: [SeazoneIconsComponent],
})
export class SeazoneIconsModule {

  static forRoot(): ModuleWithProviders<SeazoneIconsModule> {
    return {
      ngModule: SeazoneIconsModule,
      providers: [
        {
          provide: SeazoneIconRegistryService,
          useFactory: seazoneIconRegistryServiceFactory,
        },
      ],
    };
  }
}
