import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {SeazoneIcon, SeazoneIconRegistryService} from '@modules/seazone-icons';

import {SeazoneIconColor, seazoneIconColorsList} from '../../seazone-icons';

@Component({
  selector: 'app-seazone-icon',
  templateUrl: './seazone-icons.component.html',
  styleUrls: ['./seazone-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeazoneIconsComponent implements OnInit, OnDestroy {

  private svgIcon: SVGElement;
  private iconName: string;
  private iconColor: SeazoneIconColor;
  private strokeColor: SeazoneIconColor;
  private pathColor: SeazoneIconColor;
  private iconWidth: string | number;
  private iconHeight: string | number;
  private mouseover: boolean;

  @Input()
  set name(iconName: string | SeazoneIcon | null) {
    if (!iconName || iconName === this.iconName) {
      return;
    }
    this.iconName = iconName;
    if (this.svgIcon) {
      this.elementRef.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.seazoneIconRegistryService.getIcon(iconName);
    if (!svgData) {
      return;
    }
    this.svgIcon = this.svgElementFromString(svgData);
    if (this.iconColor) {
      this.svgIcon.style.fill = this.findColor(this.iconColor);
    }
    if (this.strokeColor) {
      this.svgIcon.style.stroke = this.findColor(this.strokeColor);
    }
    if (this.iconWidth) {
      this.svgIcon.setAttribute('width', `${this.iconWidth}px`);
    }
    if (this.iconHeight) {
      this.svgIcon.setAttribute('height', `${this.iconHeight}px`);
    }
    if (this.pathColor) {
      this.fillPaths(this.svgIcon, this.pathColor);
    }
    this.elementRef.nativeElement.appendChild(this.svgIcon);
  }

  @Input()
  set color(color: SeazoneIconColor) {
    this.iconColor = color;
    if (this.svgIcon && color) {
      this.svgIcon.setAttribute('fill', this.findColor(color));
    }
  }

  @Input()
  set strokedColor(color: SeazoneIconColor) {
    this.strokeColor = color;
    if (this.svgIcon && color) {
      this.svgIcon.setAttribute('stroke', this.findColor(color));
    }
  }

  @Input()
  set pathsColor(color: SeazoneIconColor) {
    this.pathColor = color;
    const svg = this.svgIcon;
    if (svg && color) {
      this.fillPaths(svg, color);
    }
  }

  @Input()
  set fillColor(color: SeazoneIconColor) {
    this.pathColor = color;
    const svg = this.svgIcon;
    if (svg && color) {
      this.fillSvgElements(svg, color);
    }
  }

  @Input() set width(val: string | number) {
    this.iconWidth = val;
    if (this.svgIcon && val) {
      this.svgIcon.setAttribute('width', `${val}px`);
    }
  }

  @Input() set height(val: string | number) {
    this.iconHeight = val;
    if (this.svgIcon && val) {
      this.svgIcon.setAttribute('height', `${val}px`);
    }
  }

  @Input() hoverColor: SeazoneIconColor;
  @Input() hoverStrokeColor: SeazoneIconColor;

  get parentEl(): HTMLElement | null {
    return this.elementRef.nativeElement.parentElement;
  }

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
    private readonly seazoneIconRegistryService: SeazoneIconRegistryService,
    @Optional() @Inject(DOCUMENT) private readonly document: any,
  ) {
  }

  ngOnInit() {
    this.addHoverListeners();
  }

  ngOnDestroy(): void {
    this.removeHoverListeners();
  }

  private addHoverListeners(): void {
    const el = this.parentEl;
    if (!this.hoverColor || this.hoverStrokeColor || !el) {
      return;
    }
    el.addEventListener('mouseover', this.onMouseOver);
    el.addEventListener('mouseout', this.onMouseOut);
  }

  private removeHoverListeners(): void {
    const el = this.parentEl;
    if (!this.hoverColor || this.hoverStrokeColor || !el) {
      return;
    }
    el.removeEventListener('mouseover', this.onMouseOver);
    el.removeEventListener('mouseout', this.onMouseOut);
  }

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }

  private findColor(color: SeazoneIconColor): string {
    const selectedColor = seazoneIconColorsList.find(item => item.name === color);
    if (!selectedColor) {
      return '#ffffff';
    }
    return selectedColor.color;
  }

  private fillPaths(svg: SVGElement, color: SeazoneIconColor): void {
    const paths = svg?.querySelectorAll('path');
    if (!paths || !paths.length) {
      return;
    }
    Array.from(paths).forEach((item) => {
      item.setAttribute('fill', this.findColor(color));
    });
  }

  private strokeCircles(svg: SVGElement, color: SeazoneIconColor): void {
    const paths = svg?.querySelectorAll('circle');
    if (!paths || !paths.length) {
      return;
    }
    Array.from(paths).forEach((item) => {
      item.setAttribute('stroke', this.findColor(color));
    });
  }

  private fillSvgElements(svg: SVGElement, color: SeazoneIconColor): void {
    this.fillPaths(svg, color);
    this.strokeCircles(svg, color);
  }

  private onMouseOver = () => {
    if (this.mouseover) {
      return;
    }
    this.mouseover = true;
    if (this.hoverColor) {
      this.svgIcon.style.fill = this.findColor(this.hoverColor);
    }
    if (this.hoverStrokeColor) {
      this.svgIcon.style.stroke = this.findColor(this.hoverStrokeColor);
    }
  };

  private onMouseOut = () => {
    if (!this.mouseover) {
      return;
    }
    this.mouseover = false;
    if (this.hoverColor) {
      this.svgIcon.style.fill = this.findColor(this.iconColor);
    }
    if (this.strokeColor) {
      this.svgIcon.style.stroke = this.findColor(this.strokeColor);
    }
  };

}
